import { PanelPlugin } from '@grafana/data';
import { RegisterProgressOptions } from './types';
import { RegisterProgressPanel } from './RegisterProgressPanel';
// import { RegisterProgressEditor } from './RegisterProgressEditor';

export const plugin = new PanelPlugin<RegisterProgressOptions>(RegisterProgressPanel)
    .setPanelOptions(builder => {
        return builder 
            .addTextInput({
                name: 'Public API name',
                path: 'publicApiName',
                category: ['Public API']
            })
            .addBooleanSwitch({
                name: 'Clear button',
                path: 'clearButton',
                category: ['Settings']
            })
            .addBooleanSwitch({
                name: 'Work order select button',
                path: 'workOrderSelectButton',
                category: ['Settings']
            })
    })
 