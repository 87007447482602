import React, { PureComponent } from 'react';
import { dateTimeParse, dateTimeFormat, TIME_FORMAT, PanelProps } from '@grafana/data';
import { RegisterProgressOptions } from 'types';
import { getDataSourceSrv as getDataSourceService } from '@grafana/runtime';
import './css/registerprogress_base.css';
import axios from 'axios';
//import BarcodeReader from 'react-barcode-reader';
const BarcodeReader = require('react-barcode-reader').default;
import appEvents from 'grafana/app/core/app_events';

interface Props extends PanelProps<RegisterProgressOptions> {}

const ENTER_KEY = 13;

export class RegisterProgressPanel extends PureComponent<Props> {
  state = {
    workOrderFilter: {
      value: undefined,
    },
    workOrderBarcode: {
      value: undefined,
    },
    goodQuantity: {
      value: undefined,
    },
    scrapQuantity: {
      value: undefined,
    },
    workOrderPartOperationDetailsID: {
      value: undefined,
    },
    proxyUrl: '',
    isTableVisible: false,
    cssLoaded: false,
    isFilterSelect: false,
    isWorkOrderFilterChanged: false,
    isWorkOrderBarcodeChanged: false,
    isGoodQuantityChanged: false,
    isScrapQuantityChanged: false,
    currentPage: 1,
  };

  proxyUrl: string | undefined;
  selectedOperator: any;
  ordersList: any[] = [];
  filteredList: any[] = [];
  currentPageList: any[] = [];
  pages = 0;
  ordersOnLastPage = 0;
  lastQuantity: any;

  constructor(props: any) {
    super(props);
    this.onSelectFilter = this.onSelectFilter.bind(this);
    this.handleScan = this.handleScan.bind(this);

    this.getProxyURL();
  }

  render() {
    return (
      <div>
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
        {this.renderHeaderInstruments()}
        {this.renderOrdersFromQuery()}
        {this.renderPagination()}
      </div>
    );
  }

  getOrdersFromQuery(): any[] {
    let result: any[] = [];
    const td = this.props.data.series[0];

    if (td.fields[0].values.length === 0) {
      return [];
    }

    if (td !== undefined && td.fields[0].values.length !== 0) {
      const tmpPartName = td.fields[1].values.toArray();
      const tmpPartPosition = td.fields[2].values.toArray();
      const tmpOperationDetailsID = td.fields[3].values.toArray();
      const tmpGoodQuantity = td.fields[4].values.toArray();
      const tmpScrapQuantity = td.fields[5].values.toArray();
      const tmpMachineNumber = td.fields[6].values.toArray();
      const tmpStartDate = td.fields[7].values.toArray();
      const tmpEndDate = td.fields[8].values.toArray();
      const tmpWorkOrderPartOperationDetailsID = td.fields[9].values.toArray();
      const tmpClosed = td.fields[10].values.toArray();

      let dateTimeParseUtc = {format: TIME_FORMAT, timeZone: 'utc'};
      let dateTimeParseCurrent = {format: TIME_FORMAT, timeZone: this.props.timeZone};

      result = td.fields[0].values.toArray().map((r: any[], i) => {
        const currentStartDate = tmpStartDate[i] ? dateTimeFormat(dateTimeParse(tmpStartDate[i], dateTimeParseUtc), dateTimeParseCurrent) : '';
        const currentEndDate = tmpEndDate[i] ? dateTimeFormat(dateTimeParse(tmpEndDate[i], dateTimeParseUtc), dateTimeParseCurrent) : '';

        return {
          workOrder: r,
          workOrderPartName: tmpPartName[i],
          workOrderPartPosition: tmpPartPosition[i],
          operationDetailsID: tmpOperationDetailsID[i],
          goodQuantity: tmpGoodQuantity[i],
          scrapQuantity: tmpScrapQuantity[i],
          machineNumber: tmpMachineNumber[i],
          startDate: currentStartDate,
          endDate: currentEndDate,
          workOrderPartOperationDetailsID: tmpWorkOrderPartOperationDetailsID[i],
          closed: tmpClosed[i],
        };
      });
    }
    return result;
  }
  getProxyURL() {
    const { publicApiName } = this.props.options;
    if (!publicApiName) {
      appEvents.emit('alert-warning', ['Datasource name not configured in settings.']);
      return;
    }

    if (this.proxyUrl !== undefined) {
      return;
    }
    const srv = getDataSourceService();
    srv.get(publicApiName).then(response => {
      const us = response as any;
      this.proxyUrl = `/api/datasources/${us.id}/resources`;
      this.setState({
        proxyUrl: this.proxyUrl,
      });
    });
  }

  updatePagesCount() {
    if (this.filteredList.length <= 10) {
      this.ordersOnLastPage = this.filteredList.length;
      this.pages = 1;
    } else if (this.filteredList.length % 10 > 0) {
      this.ordersOnLastPage = this.filteredList.length % 10;
      this.pages = Math.floor(this.filteredList.length / 10 + 1);
    } else {
      this.ordersOnLastPage = 10;
      this.pages = Math.floor(this.filteredList.length / 10);
    }
  }

  loadCurrentPageOrders() {
    this.currentPageList = [];
    if (this.state.currentPage < this.pages || (this.state.currentPage === this.pages && this.ordersOnLastPage === 0)) {
      for (let i = this.state.currentPage * 10 - 10; i < this.state.currentPage * 10 && i < this.filteredList.length; i++) {
        this.currentPageList.push(this.filteredList[i]);
      }
    }
    if (this.state.currentPage === this.pages && this.ordersOnLastPage > 0) {
      for (
        let i = this.state.currentPage * 10 - 10;
        i < this.state.currentPage * 10 - 10 + this.ordersOnLastPage && i < this.filteredList.length;
        i++
      ) {
        this.currentPageList.push(this.filteredList[i]);
      }
    }
  }

  updateGoodQuantity() {
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/erp/updateGoodQuantity';
    const data = {
      GoodQuantity: this.state.goodQuantity.value,
      WorkOrderPartOperationdetailsID: this.state.workOrderPartOperationDetailsID,
    };
    axios.post(URL, data).then(
      r => {
        appEvents.emit('alert-success', ['Successfully updated.']);
      },
      e => {
        appEvents.emit('alert-warning', ['Request failed. Contact support for more information.']);
      }
    );
  }

  updateScrapQuantity() {
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/erp/updateScrapQuantity';

    const data = {
      ScrapQuantity: this.state.scrapQuantity.value,
      WorkOrderPartOperationdetailsID: this.state.workOrderPartOperationDetailsID,
    };
    axios.post(URL, data).then(
      r => {
        appEvents.emit('alert-success', ['Successfully updated.']);
      },
      e => {
        appEvents.emit('alert-warning', ['Request failed. Contact support for more information.']);
      }
    );
  }

  filterData(data: any, filteredList: any[]) {
    const workOrderScannedBarcode = data.split(',');
    const workOrder = workOrderScannedBarcode[0];
    const woPosition = workOrderScannedBarcode[1];
    const woOperation = workOrderScannedBarcode[2];
    this.filteredList = filteredList.filter(
      wo =>
        (wo.workOrder + '').includes(workOrder) &&
        (wo.workOrderPartPosition + '').includes(woPosition) &&
        (wo.operationDetailsID + '').includes(woOperation)
    );
  }

  handleGoodKeyDown = (event: any) => {
    if (event.keyCode === ENTER_KEY && this.state.goodQuantity) {
      if (!event.target.value) {
        event.target.value = this.lastQuantity;
      } else if (isNaN(event.target.value) && !event.target.value.includes(',')) {
        appEvents.emit('alert-warning', ['Good Quantity value is not in valid format.']);
        event.target.value = this.lastQuantity;
      } else if (!event.target.value.includes(',')) {
        this.updateGoodQuantity();
      } else {
        this.setState({
          workOrderBarcode: {
            value: event.target.value,
          },
        });
        this.handleScan(event.target.value);
        event.target.value = this.lastQuantity;
      }
      this.lastQuantity = null;
      event.target.blur();
    }
  };

  handleScrapKeyDown = (event: any) => {
    if (event.keyCode === ENTER_KEY && this.state.scrapQuantity) {
      if (!event.target.value) {
        event.target.value = this.lastQuantity;
      } else if (isNaN(event.target.value) && !event.target.value.includes(',')) {
        appEvents.emit('alert-warning', ['Scrap Quantity value is not in valid format.']);
        event.target.value = this.lastQuantity;
      } else if (!event.target.value.includes(',')) {
        this.updateScrapQuantity();
      } else {
        this.setState({
          workOrderBarcode: {
            value: event.target.value,
          },
        });
        this.handleScan(event.target.value);
        event.target.value = this.lastQuantity;
      }

      this.lastQuantity = null;
      event.target.blur();
    }
  };

  handleScan(data: any) {
    this.filteredList = Array.from(this.ordersList);
    this.filterData(data, this.filteredList);
    this.setState({
      workOrderBarcode: {
        value: data,
      },
      isWorkOrderBarcodeChanged: true,
      currentPage: 1,
    });
  }

  handleError(err: any) {
    console.error(err);
  }

  onGoodQuantityChanged = (event: any) => {
    this.setState({
      goodQuantity: {
        value: event.target.value,
      },
      isGoodQuantityChanged: true,
      workOrderPartOperationDetailsID: event.target.attributes.about.nodeValue,
    });
  };

  onGoodQuantityBlur = (event: any) => {
    if (this.lastQuantity) {
      event.target.value = this.lastQuantity;
      this.lastQuantity = null;
    }
  };

  onGoodQuantityFocus = (event: any) => {
    this.lastQuantity = event.target.value;
    event.target.value = '';
  };

  onScrapQuantityChanged = (event: any) => {
    this.setState({
      scrapQuantity: {
        value: event.target.value,
      },
      isScrapQuantityChanged: true,
      workOrderPartOperationDetailsID: event.target.attributes.about.nodeValue,
    });
  };

  onScrapQuantityBlur = (event: any) => {
    if (this.lastQuantity) {
      event.target.value = this.lastQuantity;
      this.lastQuantity = null;
    }
  };

  onScrapQuantityFocus = (event: any) => {
    this.lastQuantity = event.target.value;
    event.target.value = '';
  };

  onWorkOrderFilterChange = (event: any) => {
    let workOrderFilterSet = true;
    if (!event.target.value) {
      workOrderFilterSet = false;
    }
    this.setState({
      workOrderFilter: {
        value: event.target.value,
      },
      isWorkOrderFilterChanged: workOrderFilterSet,
    });
    this.filteredList = Array.from(this.ordersList);
    if (this.state.isFilterSelect && workOrderFilterSet) {
      this.filteredList = this.filteredList.filter(wo => (wo[this.selectedOperator] + '').toLowerCase().includes(event.target.value.toLowerCase()));
      this.setState({
        currentPage: 1,
      });
    }
    // this.filteredList = this.filteredList.slice(0, 100);
  };

  onSelectFilter(event: any): void {
    this.setState({
      isFilterSelect: true,
      workOrderFilter: {
        value: '',
      },
      isWorkOrderFilterChanged: false,
    });

    this.filteredList = Array.from(this.ordersList);
    this.selectedOperator = event.target.value;
  }

  onWorkOrderBarcodeChange = (event: any) => {
    let workOrderBarcodeSet = true;
    if (!event.target.value) {
      workOrderBarcodeSet = false;
    }
    this.setState({
      workOrderBarcode: {
        value: event.target.value,
      },
      isWorkOrderBarcodeChanged: workOrderBarcodeSet,
    });
    this.filteredList = Array.from(this.ordersList);
    if (workOrderBarcodeSet) {
      this.filterData(event.target.value, this.filteredList);
      this.setState({
        currentPage: 1,
      });
    }
    // this.filteredList = this.filteredList.slice(0, 100);
  };

  onWorkOrderPartPositionChanged = (event: any) => {
    this.setState({
      onWorkOrderPartPositionChanged: {
        value: event.target.value,
      },
    });
  };

  onNextPage(): void {
    if (this.state.currentPage < this.pages) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  }

  onPreviousPage(): void {
    if (this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  }

  onReloadOrdersToVisualize(): void {
    this.loadCurrentPageOrders();
  }

  from = '';
  to = '';

  isTimeRangeChanged() {
    const urlParams = new URLSearchParams(window.location.search);
    const newFrom = urlParams.get('from');
    const newTo = urlParams.get('to');

    if (!newFrom && !newTo) {
      return false;
    }

    const changed = newFrom !== this.from || newTo !== this.to;

    this.from = newFrom + '';
    this.to = newTo + '';

    return changed;
  }

  renderOrdersFromQuery() {
    this.ordersList = this.getOrdersFromQuery();

    if (!this.state.isFilterSelect || !this.state.isWorkOrderFilterChanged) {
      // this.filteredList = Array.from(this.ordersList.slice(0, 100));
      this.filteredList = Array.from(this.ordersList);
    } else if (this.isTimeRangeChanged()) {
      this.onWorkOrderFilterChange({ target: { value: '' } });
    }

    this.updatePagesCount();
    this.loadCurrentPageOrders();

    if (this.currentPageList.length > 0) {
      return (
        <>
          <div className="table-panel-scroll">
            <table className="table-panel-table">
              <thead className="table-panel-head">
                <tr>
                  <th className="th-col-ss">
                    <div className="table-panel-header">WO</div>
                  </th>
                  <th className="th-col-ss">
                    <div className="table-panel-header">Position</div>
                  </th>
                  <th className="th-col-ss">
                    <div className="table-panel-header">Operation</div>
                  </th>
                  <th className="th-col-tb">
                    <div className="table-panel-header">Part Name</div>
                  </th>
                  <th className="th-col-s">
                    <div className="table-panel-header">Machine</div>
                  </th>
                  <th className="th-col-b">
                    <div className="table-panel-header">Start Date</div>
                  </th>
                  <th className="th-col-b">
                    <div className="table-panel-header">End Date</div>
                  </th>
                  <th className="th-col-ss">
                    <div className="table-panel-header">Closed</div>
                  </th>
                  <th className="th-col-ss">
                    <div className="table-panel-header">Good Quantity</div>
                  </th>
                  <th className="th-col-ss">
                    <div className="table-panel-header">Scrap Quantity</div>
                  </th>
                </tr>
              </thead>
              <tbody className="table-panel-body">
                {this.currentPageList.map(order => (
                  <>
                    <tr>
                      <td className="td-col-wo-ss">
                        <div className="table-panel-table-body-inner">{order.workOrder}</div>
                      </td>
                      <td className="td-col-ss">
                        <div className="table-panel-table-body-inner">{order.workOrderPartPosition}</div>
                      </td>
                      <td className="td-col-ss">
                        <div className="table-panel-table-body-inner">{order.operationDetailsID}</div>
                      </td>
                      <td className="td-col-tb">
                        <div className="table-panel-table-body-inner">{order.workOrderPartName}</div>
                      </td>
                      <td className="td-col-s">
                        <div className="table-panel-table-body-inner">{order.machineNumber}</div>
                      </td>
                      <td className="td-col-b">
                        <div className="table-panel-table-body-inner">{order.startDate}</div>
                      </td>
                      <td className="td-col-b">
                        <div className="table-panel-table-body-inner">{order.endDate}</div>
                      </td>
                      <td className="td-col-ss" style={order.closed ? { color: '#56A64B' } : { color: '#E02F44' }}>
                        <div className="table-panel-table-body-inner">{order.closed ? 'Y' : 'N'}</div>
                      </td>
                      <td className="td-col-s-change">
                        <div className="table-panel-table-body-inner">
                          <input
                            type="text"
                            className="td-col-input"
                            value={order.goodQuantity}
                            about={order.workOrderPartOperationDetailsID}
                            onChange={this.onGoodQuantityChanged}
                            onBlur={this.onGoodQuantityBlur}
                            onFocus={this.onGoodQuantityFocus}
                            onKeyDown={this.handleGoodKeyDown}
                          />
                        </div>
                      </td>
                      <td className="td-col-s-change">
                        <div className="table-panel-table-body-inner">
                          <input
                            type="text"
                            className="td-col-input"
                            value={order.scrapQuantity}
                            about={order.workOrderPartOperationDetailsID}
                            onChange={this.onScrapQuantityChanged}
                            onBlur={this.onScrapQuantityBlur}
                            onFocus={this.onScrapQuantityFocus}
                            onKeyDown={this.handleScrapKeyDown}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    }
    return <div>No data</div>;
  }

  renderHeaderInstruments() {
    return (
      <div>
        <label className="lb">Filter</label>
        <select onChange={this.onSelectFilter}>
          <option value=""> </option>
          <option value="workOrder">Work Order</option>
          <option value="workOrderPartPosition">Work Order Part Position</option>
          <option value="workOrderPartName">Work Order Part Name</option>
          <option value="operationDetailsID">Operation</option>
          <option value="machineNumber">Machine</option>
        </select>
        <input className="inp-filter" type="string" value={this.state.workOrderFilter.value} onChange={this.onWorkOrderFilterChange} />
        <label className="lb">Barcode</label>
        <input
          className="inp-barcode"
          type="string"
          placeholder="WO,WO Part Position,Operation"
          value={this.state.workOrderBarcode.value}
          onChange={this.onWorkOrderBarcodeChange}
        />
      </div>
    );
  }

  renderPagination() {
    return (
      <div className="div-pagination">
        <button className="btn-page" onClick={() => this.onPreviousPage()}>
          &laquo;
        </button>
        <span onChange={() => this.onReloadOrdersToVisualize()} className="sp-pages">
          {this.state.currentPage} of {this.pages}
        </span>
        <button className="btn-page" onClick={() => this.onNextPage()}>
          &raquo;
        </button>
      </div>
    );
  }
}
